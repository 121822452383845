<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <div class="container">
      <!-- BEGIN: Error Page -->
      <div
        class="error-page flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left"
      >
        <div class="-intro-x lg:mr-20">
          <img
            alt="Icewall Tailwind HTML Admin Template"
            class="h-48 lg:h-auto"
            :src="require(`@/assets/images/404.svg`)"
          />
        </div>
        <div class="text-white mt-10 lg:mt-0">
          <div class="intro-x text-8xl font-medium">404</div>
          <div class="intro-x text-xl lg:text-3xl font-medium mt-5">
            <!-- {{ i18n('errorPage.pageNotFound.pageNotFound') }} -->
            <app-i18n code="errors.404"></app-i18n>
          </div>
          <!-- <div class="intro-x text-lg mt-3">
            {{ i18n('errorPage.pageNotFound.wrongAddress') }}
          </div> -->

          <div class="flex items-center gap-3">
            <button
              class="intro-x btn py-3 px-4 text-white border-white dark:border-dark-5 dark:text-gray-300 mt-10"
              @click="goToHome()"
            >
              <app-i18n code="errors.backToHome"></app-i18n>
            </button>

            <button
              class="intro-x btn py-3 px-4 text-white border-white dark:border-dark-5 dark:text-gray-300 mt-10"
              @click="doSignout()"
            >
              {{ i18n('topbar.profile.logout') }}
            </button>
          </div>
        </div>
      </div>
      <!-- END: Error Page -->
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
// import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { mapActions } from 'vuex'

export default defineComponent({
  name: 'app-error-500-page',

  created() {
    document.title = this.i18n('routes.errorPage')
  },
  components: {
    // DarkModeSwitcher
  },
  methods: {
    ...mapActions({
      doSignout: 'auth/doSignout'
    }),
    goToHome() {
      this.$router.push('/')
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  },
  setup() {
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('login')
        .removeClass('login-rtl')
        .addClass('error-page')
    })
  }
})
</script>
